<template>
	<div class="pageContainer app-container studentContainer planBox" style="position: relative">
		<el-tabs type="card">
			<el-tab-pane><span slot="label"><i>问卷计划</i></span></el-tab-pane>
		</el-tabs>
		<div>
			<el-row>
				<el-input v-model="formData.plan_name" size="small" placeholder="计划名称"
					style="max-width: 200px; margin-right: 10px;" />
				<el-date-picker v-model="formData.sta_end_time" size="small" type="datetimerange" range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
					style="width: 400px; margin-right: 10px; margin-left: 10px">
				</el-date-picker>
				<el-button size="small" icon="el-icon-search" @click="onSearch" plain type="primary">查询
				</el-button>
				<el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置
				</el-button>
				<el-button type="primary" size="small" @click="onAdd">添加
				</el-button>
			</el-row>
			<el-table border stripe :data="dataList" style="width: 100%; margin-top: 20px; color: #000">
				<el-table-column prop="plan_name" label="名称" min-width="120"></el-table-column>
				<el-table-column prop="create_name" label="发布人" width="100"></el-table-column>
				<el-table-column prop="send_typeStr" label="调查对象" width="120"></el-table-column>
				<el-table-column prop="cdate" label="问卷发布时间" width="180"></el-table-column>
				<el-table-column prop="end_time" label="调查截止日期" width="180"></el-table-column>
				<el-table-column prop="notice_time" label="通知时间" width="180"></el-table-column>
				<!--				<el-table-column prop="percentage" label="完成情况" width="100"></el-table-column>-->
				<el-table-column prop="formal" label="发布状态" width="100">
					<template slot-scope="scope">
						<span v-if="scope.row.formal == 0">未发布</span>
						<span v-if="scope.row.formal == 1" style="color: #67c23a;">已发布</span>
					</template>
				</el-table-column>
				<el-table-column prop="" label="二维码" width="100">
					<template slot-scope="scope">
						<div class="qrcode" @click="downImage(scope.row.id)" :id="'qrcode_' + scope.row.id"
							style="width: 40px; height: 40px;"></div>
					</template>

				</el-table-column>

				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<div class="flexStart">
							<el-popconfirm title="确定删除吗？" @confirm="onDel(scope.row)">
								<el-tag slot="reference" type="danger" size="mini" style="cursor: pointer;">删除</el-tag>
							</el-popconfirm>
							<el-tag size="mini" @click="onModify(scope.row)" style="margin-left: 10px;cursor: pointer;">
								编辑</el-tag>
						</div>
						<div class="flexStart" style="margin-top: 10px;">
							<el-tag type="info" color="" size="mini" @click="onViewComplete(scope.row)"
								style="cursor: pointer;">完成度</el-tag>
							<el-tag type="warning" size="mini" @click="onView(scope.row)"
								style="margin-left:10px;cursor: pointer;">查看
							</el-tag>
							<el-tag type="success" size="mini" @click="onReport(scope.row)"
								style="margin-left: 10px;cursor: pointer;">报告
							</el-tag>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination layout="total, prev, pager, next" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current_page" :page-size="page.per_page"
				:total="page.count">
			</el-pagination>
		</div>


		<el-dialog :title="dialodTitle" :visible.sync="showDialog" width="800px" custom-class="width_800 cus_dialog">
			<el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="80px">
				<el-form-item label="计划名称" prop="plan_name">
					<el-input v-model="ruleForm.plan_name" size="small" placeholder="计划名称"></el-input>
				</el-form-item>
				<el-form-item label="调查时段" prop="sta_end_time">
					<el-date-picker size="small" v-model="ruleForm.sta_end_time" type="datetimerange" range-separator="至"
						start-placeholder="开始时间" end-placeholder="结束时间" :default-time="['00:00:00', '00:00:00']">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="定时发送">
					<el-date-picker size="small" v-model="ruleForm.notice_time" type="datetime" placeholder="选择定时时间">
					</el-date-picker>
					<span style="color: #409eff;">（若不填此项，则默认立即发送）</span>
				</el-form-item>

				<div class="requiredLabel" v-for="(item, index) in ruleForm.moban_arr" :key="index"
					style="margin: 20px 0 0 20px;">
					<div style="color: #409eff;">问卷模板{{ index + 1 }}：
						<el-popconfirm title="确定删除模板吗？" @confirm="delTemplate(index)">
							<el-tag slot="reference" type="danger" size="mini" style="margin-left: 5px;">删除</el-tag>
						</el-popconfirm>
					</div>
					<el-form-item label="调查对象:" style="margin-bottom: 0">
						<el-radio-group v-model="item.type" @change="e => changeRadio(e, index)">
							<el-radio :label="0">学生</el-radio>
							<el-radio :label="2">企业</el-radio>
							<el-radio :label="1">家长</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="调查方式:" style="margin-bottom: 0">
						<el-checkbox-group v-if="item.type == 0" v-model="item.send_type">
							<el-checkbox :label="0">小程序</el-checkbox>
							<el-checkbox :label="2">短信</el-checkbox>
							<el-checkbox :label="1">邮件</el-checkbox>
						</el-checkbox-group>
						<el-checkbox-group v-if="item.type == 2" v-model="item.send_type">
							<el-checkbox :label="2">短信</el-checkbox>
							<el-checkbox :label="1">邮件</el-checkbox>
						</el-checkbox-group>
						<el-checkbox-group v-if="item.type == 1" v-model="item.send_type">
							<el-checkbox :label="2">短信</el-checkbox>
						</el-checkbox-group>
					</el-form-item>

					<el-form-item v-if="item.type == 0" label="学生模板:" style="margin-bottom: 0">
						<el-select v-model="item.ivst_id" placeholder="请选择" size="small" @change="$forceUpdate()">
							<el-option v-for="items in moban_list00" :key="items.id" :label="items.ivst_title"
								:value="items.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="item.type == 2" label="企业模板:" style="margin-bottom: 0">
						<el-select v-model="item.ivst_id" placeholder="请选择" size="small" @change="$forceUpdate()">
							<el-option v-for="items in moban_list02" :key="items.id" :label="items.ivst_title"
								:value="items.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="item.type == 1" label="家长模板:" style="margin-bottom: 0">
						<el-select v-model="item.ivst_id" placeholder="请选择" size="small" @change="$forceUpdate()">
							<el-option v-for="items in moban_list01" :key="items.id" :label="items.ivst_title"
								:value="items.id">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item v-if="item.type == 0" label="发送对象:" style="margin-bottom: 0">
						<el-cascader :show-all-levels="false" v-model="item.target_user" size="mini" :options="studentsTree"
							:props="props" collapse-tags clearable @change="e => changeCascader(e, index)">
							<template slot-scope="{ node, data }">
								<span v-if="data.name">{{ data.name }}</span>
								<span v-if="data.stu_name">{{ data.stu_name }}</span>
							</template>

						</el-cascader>
						<el-tag type="primary" size="mini" @click="chooseAllCascader(index)"
							style="margin-left: 10px;cursor: pointer;">一键全选
						</el-tag>

					</el-form-item>

					<el-form-item v-if="item.type == 2" label="发送对象:" style="margin-bottom: 0">
						<div>
							<el-checkbox :indeterminate="item.isIndeterminate" v-model="item.checkAll"
								@change="checkAllCo(index)">
								全部企业
							</el-checkbox>
							<el-tag type="success" size="mini" @click="openCoList(index)" style="margin-left: 10px;">
								点击查看</el-tag>
						</div>

					</el-form-item>

					<el-form-item v-if="item.type == 1" label="发送对象:" style="margin-bottom: 0">
						<el-cascader :show-all-levels="false" v-model="item.target_user" size="mini" :options="studentsTree"
							:props="props" collapse-tags clearable @change="e => changeCascader(e, index)">
							<template slot-scope="{ node, data }">
								<span v-if="data.name">{{ data.name }}</span>
								<span v-if="data.stu_name">{{ data.stu_name }}</span>
							</template>

						</el-cascader>
						<el-tag type="primary" size="mini" @click="chooseAllCascader(index)"
							style="margin-left: 10px;cursor: pointer;">一键全选
						</el-tag>

					</el-form-item>
				</div>
				<el-button type="success" size="mini" plain icon="el-icon-plus" @click="addTemplate" style="margin: 20px;">
					新增模板
				</el-button>


				<el-form-item label="发布状态" prop="formal">
					<el-radio-group v-model="ruleForm.formal">
						<el-radio :label="1">启用</el-radio>
						<el-radio :label="0">存草稿</el-radio>
					</el-radio-group>
				</el-form-item>

			</el-form>

			<div slot="footer" class="dialog-footer flexCenter">
				<el-button @click="showDialog = false">取 消</el-button>
				<el-button type="primary" @click="onSubmit('ruleForm')">提交</el-button>
			</div>
		</el-dialog>

		<!--    企业列表-->
		<el-dialog title="企业列表" :visible.sync="showDialog_co" width="600px" custom-class="width_800 cus_dialog">
			<el-table ref="coMuliTable" border stripe :data="coList" tooltip-effect="dark" @select="muliSelectTableCo"
				@select-all="muliSelectTableCo_all" style="width: 100%;">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="firm_name" label="名称" min-width="150"></el-table-column>
				<el-table-column prop="firm_principal" label="联系人" width="80"></el-table-column>
				<el-table-column prop="phone" label="联系电话" width="100"></el-table-column>

				<el-table-column prop="is_cooperation" label="是否合作" width="80">
					<template slot-scope="scope">
						<span v-if="scope.row.is_cooperation == 0">不合作</span>
						<span v-if="scope.row.is_cooperation == 1" style="color: #67c23a;">合作</span>
					</template>
				</el-table-column>
			</el-table>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="closeCoList" size="small">完成</el-button>
			</div>
		</el-dialog>

		<!--    完成度-->
		<el-dialog :title="viewTitle" :visible.sync="showDialog_complete" custom-class="cus_dialog_complete width_800">
			<div class="flex_1"
				style="padding:20px 10px;height: 100%;overflow-y: scroll;box-shadow: 0 0 4px 0 #00000051;border-radius: 10px;">
				<div v-for="(item, index) in completeList" :key="index" :id="'dept' + item.index" class=""
					style="width: 100%;height: 400px;"></div>
			</div>
			<div class=""
				style="width:380px;padding:20px 10px 20px 0;margin-left:20px;height: 100%;overflow-y: scroll;box-shadow: 0 0 4px 0 #00000051;border-radius: 10px;">
				<el-tree :data="completeList" :props="defaultProps" :render-content="renderContent"
					:default-expanded-keys="expandedKeys" node-key="name" :expand-on-click-node="false"></el-tree>

			</div>
		</el-dialog>

		<!--    查看-->
		<el-dialog :title="viewTitle" :visible.sync="showDialog_preview" custom-class="cus_dialog_preview">
			<el-row class="previewBox">
				<el-col :span="10" style="height: 100%;">
					<div class="left">
						<el-collapse v-model="ckdViewIvstID">
							<el-collapse-item v-for="(item, index) in tempList" :key="index" :title="item.ivst_title"
								:name="'' + item.ivst_id">
								<div class="tmItem" @click="analysisOne(c, ic + 1)" v-for="(c, ic) in tjTmlist" :key="ic"
									:style="'border-color:' + colorList[ic]">
									<div class="tmTitle">{{ ic + 1 }}. {{ c.tm_title }}</div>
									<div class="tmOptions flexStart">
										<div v-for="(o, io) in c.tm_content" :key="io" style="margin-right: 30px;">
											{{ getIdx(io) }}.
											{{ o.title }}
										</div>
									</div>
								</div>
							</el-collapse-item>
						</el-collapse>
					</div>
				</el-col>
				<el-col :span="14" style="height: 100%;">
					<div v-show="hasXX" class="right flexCloumnSingleCenter">
						<div class="flexCenter" style="margin-top: 10%;font-size: 18px;font-weight: 700;">
							{{ tmOne.indexC }}. {{ tmOne.tm_title }}
						</div>
						<div id="mychart"
							style="margin-top: 10%;width:80%;height:50%;box-shadow: 0 0 10px rgba(0,0,0,0.3);">

						</div>
					</div>
					<div v-show="!hasXX">
						<div style="padding:0 30px;">


							<div class="flexCenter" style="margin-top: 10%;font-size: 18px;font-weight: 700;">
								{{ tmOne.indexC }}. {{ tmOne.tm_title }}
							</div>
							<div class="words" v-for="(w, i) in wordsList" :key="i">
								{{ w }}
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</el-dialog>

		<!--    报告-->

		<el-dialog :title="reportTitle" :visible.sync="showDialog_report" custom-class="cus_dialog_report">
			<div slot="title" class="flexBetween" style="margin-right: 180px;">
				<div>{{ reportTitle }}</div>
				<el-button size="small" icon="el-icon-download" type="primary" @click="exportReport('问卷调查报告模板.docx')"
					style="margin-left: 100px;">导出调研报告
				</el-button>
			</div>
			<div class="reportBox">
				<el-tabs v-model="active_report" @tab-click="tabClick">
					<el-tab-pane label="概况" name="first">
						<div class="flexBetween" style="text-align: center; padding: 0 50px ;">
							<div style="width: 300px;">
								<div class="boxitem">
									<div class="boxdesc">
										需完成总人数：{{ gkInfo.stu_total }}
									</div>

								</div>
								<div class="boxitem">
									<div class="boxdesc">
										已完成人数：{{ gkInfo.finish_ok }}
									</div>

								</div>
								<div class="boxitem">
									<div class="boxdesc">
										未完成人数：{{ gkInfo.finish_not }}
									</div>

								</div>
							</div>
							<div style="flex-grow: 1; text-align: center;">
								<img src="../../../../public/img/plan_001.jpg" alt=""
									style="max-width: 100%;max-height: 100%;">
							</div>
							<div style="width: 300px;">
								<div class="boxitem">
									<div class="boxdesc">
										<div style="line-height: 30px;">
											小程序完成人数：{{ gkInfo.type_mini }}人
											<br />
											占比{{ gkInfo.type_mini_p }}%
										</div>

									</div>

								</div>
								<div class="boxitem">
									<div class="boxdesc">
										<div style="line-height: 30px;">
											手机短信成完成人数：{{ gkInfo.type_sms }}人
											<br />
											占比{{ gkInfo.type_sms_p }}%
											<br />
										</div>
									</div>
								</div>
								<div class="boxitem">
									<div class="boxdesc">
										<div style="line-height: 30px;">
											邮件完成人数：{{ gkInfo.type_mail }}人
											<br />
											占比{{ gkInfo.type_mail_p }}%
										</div>

									</div>
								</div>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane :label="tm.tm_title" :name="'idx_' + tm.id" v-for="(tm, idx) in tjTmlist" :key="idx">

					</el-tab-pane>
					<div v-show="active_report != 'first'">
						<div v-show="hasXX">


							<div id="chartitem1"
								style="width:30%; height: 600px; display: inline-block; vertical-align: top;">

							</div>
							<div id="chartitem2"
								style="width:30%; height: 600px; margin-left: 3%; display: inline-block; vertical-align: top;">

							</div>
							<div id="chartitem3"
								style="width:30%; height: 600px; margin-left: 3%; display: inline-block; vertical-align: top;">

							</div>
						</div>
						<div v-show="!hasXX">
							<div class="words" v-for="(w, i) in wordsList" :key="i">
								{{ w }}
							</div>
						</div>
					</div>




				</el-tabs>
			</div>
		</el-dialog>


	</div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
	components: {},
	data() {
		return {
			page: {
				count: 0,
				current_page: 1,
				per_page: 20,
			},

			dataList: [],
			formData: {},
			ruleForm: {
				sort: 1,
				formal: 1,
				moban_arr: [{
					type: 0,
					send_type: [0, 1, 2],
					checkAll: true,
					isIndeterminate: false,
					selectCoList: [],
				}],
			},
			// isIndeterminate: false,
			// checkAll: true,
			showDialog_co: false,
			coList: [],
			indexCo: 0,
			// selectCoList:[],
			colorList: ['#67c23a', '#f56c6c', '#409eff',],
			rules: {
				plan_name: [{
					required: true,
					message: "请输入计划名称",
					trigger: "blur"
				},],
				sta_end_time: [{
					required: true,
					message: "请选择调查时段",
					trigger: "blur"
				},],
				formal: [{
					required: true,
					message: "请选择发布状态",
					trigger: "blur"
				},],
			},
			showDialog: false,
			dialodTitle: '问卷计划新增',
			moban_list00: [], //学生
			moban_list02: [], //企业
			moban_list01: [], //家长
			props: {
				multiple: true,
				emitPath: false,
				value: 'id',
				label: 'stu_name'
			},

			studentsTree: [],
			showDialog_preview: false,
			showDialog_complete: false,
			viewTitle: '',
			showDialog_report: false,
			showDialog_report2: false,
			reportTitle: '',
			active_report: 'first',
			tempList: [],
			ids: [],
			tmOne: {},

			list: [],
			gkInfo: {},
			tjTmlist: [],
			hasXX: false,
			wordsList: [],
			ckdViewIvstID: [],
			plan_id: 0,
			completeList: [],
			defaultProps: {
				children: 'arr',
				label: 'name'
			},
			rateList: [],
			expandedKeys: [],
		};
	},
	mounted() {
		// console.log(sessionStorage.getItem("auth"));
		this.getlist();
		this.getMobanArrs()
		this.getStudentsTree()
		this.getCoList()

	},
	methods: {

		expandedTree(data) {
			this.expandedKeys = [];
			for (var i = 0; i < data.length; i++) {
				for (var j = 0; j < data[i].arr.length; j++) {
					this.expandedKeys.push(data[i].arr[j].name);//循环data的数据，把对应要展开的节点name放入展开的数组中
				}
			}
		},
		getlist() {
			this.dataList = []
			this.$http
				.post("api/by_ivst_plan_list", {
					page: this.page.current_page,
					pagesize: this.page.per_page,
					plan_name: this.formData.plan_name,
					sta_time: this.formData.sta_end_time ? this.formData.sta_end_time[0] : '',
					end_time: this.formData.sta_end_time ? this.formData.sta_end_time[1] : '',
					sta_end_time: this.formData.sta_end_time,
				})
				.then((res) => {
					if (res.data && res.data.data && res.data.data.length > 0) {
						let rData = res.data.data
						rData.map(a => {
							let str = ''
							if (a.moban_arr && a.moban_arr.length > 0) {
								a.moban_arr.map(m => {
									str += String(m.type)
								})

								let a0 = '',
									a1 = '',
									a2 = ''
								if (str.indexOf('0') != -1) {
									a0 = '学生'
								}
								if (str.indexOf('1') != -1) {
									a1 = '家长'
								}
								if (str.indexOf('2') != -1) {
									a2 = '企业'
								}
								a.send_typeStr = `${a0} ${a2} ${a1}`
							}
						})
						this.dataList = rData;
						this.page.count = res.data.page.count

						this.$nextTick(() => {
							this.creatQrCode()
						})
					}
				});
		},
		creatQrCode() {
			for (let item of this.dataList) {
				let qrcodetxt = window.location.href.split("#")[0] + "#/p/" + item.pmid + "." + item.id + ".s.0.m" + '?pmid=' + item.pmid
				// console.log(526,qrcodetxt)
				var qrcode = new QRCode("qrcode_" + item.id, {
					text: qrcodetxt, // 需要转换为二维码的内容
					width: 500,
					height: 500,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
			}

		},
		getMobanArrs() {
			this.$http.post("api/ivst_list", {
				page: 1,
				pagesize: 10000,
			})
				.then((res) => {
					if (res.data.data && res.data.data.length > 0) {
						let groupArr = this.groupBy(res.data.data, function (item) {
							return [item.target_user]
						})
						let moban_list00 = [],
							moban_list02 = [],
							moban_list01 = []
						if (groupArr && groupArr.length > 0) {
							groupArr.map(a => {
								if (a[0].target_user == 'student') {
									moban_list00 = a
								}
								if (a[0].target_user == 'enterpirse') {
									moban_list02 = a
								}
								if (a[0].target_user == 'parent') {
									moban_list01 = a
								}

								this.moban_list00 = moban_list00
								this.moban_list02 = moban_list02
								this.moban_list01 = moban_list01

							})
						}
					}
				});
		},
		groupBy(array, f) {
			let groups = {};
			array.forEach(function (o) {
				let group = JSON.stringify(f(o));
				groups[group] = groups[group] || [];
				groups[group].push(o);
			});
			return Object.keys(groups).map(function (group) {
				return groups[group];
			});
		},

		getStudentsTree() {
			this.$http.post("api/by_students_tree").then((res) => {
				if (res.data && res.data.length > 0) {
					this.studentsTree = res.data
				}
			});
		},
		getCoList() {
			let _this = this
			_this.$http.post("api/by_enterprise_list").then((res) => {
				if (res.data.data && res.data.data.length > 0) {
					_this.coList = res.data.data
					_this.ruleForm.moban_arr[0].selectCoList = res.data.data
				}
			});
		},
		onSearch() {
			this.getlist();
		},
		onReset() {
			this.formData = {};
			this.page.current_page = 1;
			this.getlist();
		},
		handleSizeChange(val) {
			// console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page.current_page = val;
			this.getlist();
		},
		onAdd() {
			this.ruleForm = {
				sort: 1,
				formal: 1,
				moban_arr: [{
					type: 0,
					send_type: [0, 1, 2],
					checkAll: true,
					isIndeterminate: false,
					selectCoList: this.coList,
				}],
			}

			this.showDialog = true

		},
		onModify(e) {
			this.dialodTitle = '问卷计划修改'
			let info = JSON.parse(JSON.stringify(e))
			let sta_end_time = [];
			sta_end_time.push(new Date(e.sta_time));
			sta_end_time.push(new Date(e.end_time));
			info.sta_end_time = sta_end_time
			if (info.moban_arr && info.moban_arr.length > 0) {
				info.moban_arr.map((a, index) => {
					if (a.type == 2 && a.target_user) {
						let idstr = a.target_user
						let arr = []
						a.target_user = a.target_user.split(',')
						a.target_user.shift()
						a.target_user.pop()
						this.coList.map(a => {
							if (idstr.indexOf(a.id) != -1) {
								arr.push(a)
							}
						})

						a.selectCoList = arr

						let checkedCount = a.selectCoList.length;
						a.checkAll = checkedCount === this.coList.length;
						a.isIndeterminate = checkedCount > 0 && checkedCount < this.coList.length;
					} else if (a.type == 2 && !a.target_user) {
						a.selectCoList = []
					}
					if ((a.type == 0 || a.type == 1) && a.target_user) {
						// console.log(539,a.target_user)
						a.target_user = a.target_user.split(',')
						a.target_user.shift()
						a.target_user.pop()
					}
					if (a.send_type) {
						a.send_type = a.send_type.split(',')
						a.send_type = a.send_type.map(a => {
							return Number(a)
						})
					}

				})
			} else {
				info.moban_arr = []
			}

			this.ruleForm = {
				...info
			};
			this.showDialog = true;

		},

		groupBy(array, f) {
			let groups = {}
			array.forEach(function (o) {
				let group = JSON.stringify(f(o))
				groups[group] = groups[group] || []
				groups[group].push(o)
			})
			return Object.keys(groups).map(function (group) {
				return groups[group]
			})
		},

		onViewComplete(e) {
			// console.log(e)
			this.$http.post("api/get_ivst_dtqk", {
				plan_id: e.id,
				pmid: 1,
			}).then(res => {
				// console.log(res.data.data)
				this.viewTitle = e.plan_name
				this.showDialog_complete = true
				let ok = 0, no = 0, rateList = [], list = []
				// console.log(733, res.data.data)
				res.data.data && res.data.data.map((a, ia) => {
					// console.log(734, a)

					a.index = ia
					a.type = 'dept'
					list = []
					a.arr && a.arr.map(m => {
						m.arr && m.arr.map(b => {

							// })
							//  	a.arr[0].arr && a.arr[0].arr.map(b => {
							ok = 0, no = 0
							b.arr && b.arr.map(ss => {
								// console.log(ss)
								if (ss.id) {
									ok += 1
								} else {
									no += 1
								}
							})
							b.ok = ok
							b.no = no
							b.total = parseInt(ok) + parseInt(no)
							if (b.total == 0) {
								b.rate = 0
							} else {
								b.rate = parseFloat((ok / b.total) * 100).toFixed(2)
							}
							list.push(b)
						})
						rateList.push({
							name: a.name,
							index: a.index,
							list: list
						})
					})

					// console.log(765, rateList)

				})
				let completeList = JSON.parse(JSON.stringify(res.data.data))
				completeList.map(a => {
					a.arr.map((b, bi) => {
						let list = []
						// console.log(765, a.arr)
						let groupArr = this.groupBy(b.arr, function (item) {
							return [item.class_name]
						})
						groupArr.map(g => {
							// g.map(o => {
							// 	let obj = {
							// 		name: o.class_name,
							// 		arr:g
							// 	}
							// })
							let obj = {
								name: g[0].class_name,
								type: 'classcopy',
								arr: g
							}
							list.push(obj)
						})
						b.arr = list

						console.log(780, bi, groupArr)
					})
				})
				// a.groupArr = []

				this.completeList = completeList
				this.rateList = rateList
				// console.log(765, rateList)

				rateList.map(a => {
					a.groupArr = []
					let groupArr = this.groupBy(a.list, function (item) {
						return [item.class_name]
					})
					a.classArr = []
					groupArr.map(b => {
						let total = 0, ok = 0, no = 0, ok_rate = 0, ok_rate_label = ''
						b.map(e => {
							total += 1
							if (e.id) {
								ok += 1
							}
						})

						no = total - ok
						if (total == 0) {
							ok_rate = 0
						} else {
							let rate = ok / total;
							ok_rate = Math.round(rate * 100)
							ok_rate_label = Math.round(rate * 100) + '%'
						}


						let obj = {
							class_name: b[0].class_name,
							total: total,
							no: no,
							ok: ok,
							ok_rate: ok_rate,
							ok_rate_label: ok_rate_label
						}
						a.classArr.push(obj)
					})
					a.groupArr = groupArr
					// console.log(801, a)

				})
				// console.log(740, this.rateList)
				this.$nextTick(() => {
					this.expandedTree(res.data.data)
					for (let item of rateList) {
						// console.log('dept' + item.index)
						// console.log(759, item)
						let myChart = this.$echarts.init(document.getElementById('dept' + item.index))
						myChart.clear();
						let option = {
							color: this.colorList,
							tooltip: {
								trigger: 'axis',
								axisPointer: {
									type: 'cross',
									crossStyle: {
										color: '#999'
									}
								}
							},
							title: [{
								text: item.name
							}],
							grid: {
								left: '10%',
								bottom: '100px'
							},
							legend: {
								data: ['已完成人数', '未完成人数', '完成百分比']
							},
							xAxis: [{
								type: 'category',
								data: item.classArr.map(e => {
									return e.class_name
								}),
								axisPointer: {
									type: 'shadow'
								},
								axisLabel: { // 坐标轴标签
									show: true, // 是否显示
									inside: false, // 是否朝内
									rotate: 45, // 旋转角度
									margin: 5, // 刻度标签与轴线之间的距离
									color: '#333', // 默认取轴线的颜色
									// formatter: function (value) {
									// 	return value.split("").join("\n");
									// }
								},
							}],
							yAxis: [{
								type: 'value',
								name: '人数',
								min: 0,
								max: 100,
								axisLabel: {
									formatter: '{value} 人'
								}
							},

							{
								type: 'value',
								name: '百分比',
								min: 0,
								max: 100,
								axisLabel: {
									formatter: '{value} %'
								}
							}
							],
							series: [{
								name: '已完成人数',
								type: 'bar',
								data: item.classArr.map(e => {
									return e.ok
								}),
							},
							{
								name: '未完成人数',
								type: 'bar',
								data: item.classArr.map(e => {
									return e.no
								}),
							},
							{
								name: '完成百分比',
								type: 'bar',
								data: item.classArr.map(e => {
									return e.ok_rate
								}),
							}
							]
						};
						myChart.setOption(option)
					}
				})


			})
		},

		renderContent(h, { node, data, store }) {
			// console.log('1h',h,'2node',node,'3data', data,'4store',store)
			return (
				<div class="custom-tree-node" style="width:100%;">
					<div class="flexBetween" style="width:100%;">
						<div className="flexStart" style="width:100%;">
							<span style="min-width:80px;">{data.stu_name ? data.stu_name : node.label}</span>
							<span style="color:#ff8400;">{data.stu_name && !data.id ? '未填写' : ''}</span></div>
						{data.type && data.type == 'classcopy' ? <el-tag onClick={(e) => this.onCopy(e, data)} size="mini">一键复制未完成名单</el-tag> : ''}
					</div>
				</div>
			);
		},
		onCopy(e, data) {
			e.preventDefault()
			// console.log(data)
			let text = ''
			text = `${data.name}未填写人员名单：
			`
			data.arr && data.arr.map(a => {
				// let flag = 0
				// a.arr && a.arr.map(b => {
				// 	if (!b.id) {
				// 		flag = 1
				// 	}
				// })
				// if (flag) {
				text += `${a.stu_name}，`
				// 			a.arr && a.arr.map(b => {
				// 				if (!b.id) {
				// 					text = `${text}
				//   ${b.stu_name}`
				// 				}
				// 			})
				// }
			})
			// this.handleClipboard(data)


			// console.log(123456,text)


			let textareaEl = document.createElement('textarea')  // 创建一个元素
			textareaEl.value = text   // 将需要复制的内容传给该元素作为文本
			document.body.appendChild(textareaEl)
			textareaEl.select()   // 自动选中
			document.execCommand('copy')  // 这个命令会将选中的内容复制到粘贴板中
			document.body.removeChild(textareaEl)

		},
		onView(e) {
			console.log(e)
			this.$http.post("api/h5_ivst_plan", {
				plan_id: e.id
			}).then(res => {
				this.tempList = res.data.temp
				this.viewTitle = res.data.plan_name
				this.showDialog_preview = true

				this.changeIvst(this.tempList[0])
			})
		},
		changeIvst(item) {
			this.ckdViewIvstID = [item.ivst_id + ""]

			this.$http.post("api/get_ivst_tjinfo", {
				plan_id: item.plan_id,
				ivst_id: item.ivst_id
			}).then(res => {
				this.tjTmlist = res.data
				this.analysisOne(this.tjTmlist[0])
			})
		},

		analysisOne(c, index) {



			let arr_y = [],
				data = []
			this.tmOne = c
			this.tmOne.indexC = index
			if (c.tm_type.indexOf("radio") >= 0 || c.tm_type.indexOf("checkbox") >= 0) {
				this.hasXX = true
				this.$nextTick(() => {
					this.initChart(c)
				})

			} else {
				this.hasXX = false
				this.wordsList = c.answers;
			}
		},
		initChart(c) {
			let _this = this
			let myChart1 = this.$echarts.init(document.getElementById('mychart'))
			let colorList = ['#73c0de', '#91cc75', '#fac858', '#ee6666', '#3ba272',
				'#fc8452', '#9a60b4', '#ea7ccc', '#5470c6'
			]
			let data = c

			myChart1.clear();
			let option = {

				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},

				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'value',
					boundaryGap: [0, 0.01]
				},
				yAxis: {
					type: 'category',
					data: data.tm_content.map(e => {
						return e.title
					})
				},
				series: [{
					name: '选择人数',
					type: 'bar',

					itemStyle: {
						color: function (p) {
							return colorList[p.dataIndex]
						}
					},
					data: data.tm_content.map(e => {
						return e.choose_count
					})
				},

				]
			};
			myChart1.setOption(option)
		},
		initChartTM(data) {
			let colorList = ['#73c0de', '#91cc75', '#fac858', '#ee6666', '#3ba272',
				'#fc8452', '#9a60b4', '#ea7ccc', '#5470c6'
			]
			let _this = this
			let myChart1 = this.$echarts.init(document.getElementById('chartitem1'))
			myChart1.clear();
			let option = {
				title: {
					text: '选项统计',
					textAlign: "center",
					x: 'center',
					y: 'top',
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},

				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'value',
					boundaryGap: [0, 0.01]
				},
				yAxis: {
					type: 'category',
					data: data.tm_content.map(e => {
						return e.title
					})
				},
				series: [{
					name: '选择人数',
					type: 'bar',
					barWidth: 30,
					itemStyle: {
						color: function (p) {
							return colorList[p.dataIndex]
						}
					},
					data: data.tm_content.map(e => {
						return e.choose_count
					})
				},

				]
			};
			myChart1.setOption(option)


			let myChart2 = this.$echarts.init(document.getElementById('chartitem2'))
			myChart2.clear();
			let option2 = {
				tooltip: {
					trigger: 'item'
				},
				legend: {
					top: '5%',
					left: 'center'
				},

				color: colorList,
				series: [{
					name: '选择人数',
					type: 'pie',
					radius: ['40%', '70%'],
					avoidLabelOverlap: false,
					itemStyle: {
						borderRadius: 10,
						borderColor: '#fff',
						borderWidth: 2
					},
					label: {
						show: false,
						position: 'center'
					},
					emphasis: {
						label: {
							show: true,
							fontSize: '40',
							fontWeight: 'bold'
						}
					},
					labelLine: {
						show: false
					},
					data: data.tm_content.map(e => {
						return {
							value: e.choose_count,
							name: e.title
						}
					})


				}]
			};
			myChart2.setOption(option2)

			let myChart3 = this.$echarts.init(document.getElementById('chartitem3'))
			myChart3.clear();
			let option3 = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '10%',
					containLabel: true
				},

				xAxis: {
					type: 'category',
					axisLabel: {
						interval: 0,
						rotate: 40
					},
					data: data.tm_content.map(e => {
						return e.title
					})
				},
				yAxis: {
					type: 'value'
				},

				series: [{
					data: data.tm_content.map(e => {
						return e.choose_count
					}),
					type: 'line',
					smooth: true
				}]
			};
			myChart3.setOption(option3)
		},


		onDel(e) {
			this.$http.post("api/by_ivst_plan_delete", {
				id: e.id,
			}).then(res => {
				this.$message('删除成功！')
				this.page.current_page = 1;
				this.getlist();
			});
		},
		onReport(e) {
			this.plan_id = e.id
			this.$http.post("api/get_ivst_tj_gk", {
				plan_id: e.id,
			}).then(res => {

				let gkInfo = res.data;
				if (gkInfo.finish_ok > 0) {
					gkInfo.type_mini_p = parseInt(gkInfo.type_mini / gkInfo.finish_ok * 10000) / 100
					gkInfo.type_sms_p = parseInt(gkInfo.type_sms / gkInfo.finish_ok * 10000) / 100
					gkInfo.type_mail_p = parseInt(gkInfo.type_mail / gkInfo.finish_ok * 10000) / 100

				} else {
					gkInfo.type_mini_p = 0
					gkInfo.type_sms_p = 0
					gkInfo.type_mail_p = 0
				}

				this.gkInfo = gkInfo;
				this.reportTitle = e.plan_name
				this.showDialog_report = true

				this.$http.post("api/get_ivst_tj_tm", {
					plan_id: e.id,
				}).then(res => {

					this.tjTmlist = res.data
					this.active_report = "first"
				})
			});

		},
		tabClick(e) {

			if (this.active_report != "first") {
				let tmid = this.active_report.replace("idx_", "")
				let tm = null;
				for (let item of this.tjTmlist) {
					if (item.id == tmid) {
						tm = item;
						break
					}
				}

				if (tm) {
					if (tm.tm_type.indexOf("radio") >= 0 || tm.tm_type.indexOf("checkbox") >= 0) {

						this.hasXX = true
						this.$nextTick(() => {
							this.initChartTM(tm)
						})
					} else {
						this.hasXX = false
						this.wordsList = tm.answers
					}


				}
			} else {

			}

		},

		addTemplate(index) {
			let obj = {
				type: 0,
				send_type: [0, 1, 2],
				isIndeterminate: false,
				checkAll: true,
				selectCoList: this.coList,
			}
			this.ruleForm.moban_arr.push(obj)

		},
		delTemplate(index) {
			this.ruleForm.moban_arr.splice(index, 1)
		},
		changeRadio(e, index) {
			console.log(e, index)
			let _this = this
			this.indexCo = index
			this.ruleForm.moban_arr[index].ivst_id = ""
			this.ruleForm.moban_arr[index].target_user = []
			this.ruleForm.moban_arr[index].selectCoList = this.coList
			if (e == 0) {
				this.ruleForm.moban_arr[index].send_type = [0, 2, 1]
			}
			if (e == 2) {
				this.ruleForm.moban_arr[index].send_type = [2, 1]
			}
			if (e == 1) {
				this.ruleForm.moban_arr[index].send_type = [2]
			}
			this.ruleForm = {
				..._this.ruleForm
			}
		},
		changeCascader(e, index) {
			// console.log(e, index)
			this.indexCo = index
			this.ruleForm.moban_arr[index].target_user = e

		},
		chooseAllCascader(index) {
			let _this = this
			this.indexCo = index
			let arr = this.setVal()
			this.ruleForm.moban_arr[index].target_user = []
			this.ruleForm.moban_arr[index].target_user = arr
			// console.log(arr)
			this.ruleForm = {
				..._this.ruleForm
			}
		},
		//学生树全选遍历赋值
		setVal() {
			let arr = []
			this.studentsTree.map(a => {
				a.children && a.children.map(b => {
					b.children && b.children.map(c => {
						c.children && c.children.map(d => {
							arr.push(d.id)
						})
					})
				})
			})
			// console.log(arr)
			return arr
		},
		checkAllCo(index) {
			this.indexCo = index
			this.ruleForm.moban_arr[index].checkAll = !this.ruleForm.moban_arr[index].checkAll
			this.ruleForm.moban_arr[index].isIndeterminate = false
			this.ruleForm.moban_arr[index].selectCoList = this.coList
		},
		openCoList(index) {
			let _this = this
			_this.indexCo = index
			this.showDialog_co = true
			_this.$nextTick(function () {
				_this.$refs.coMuliTable.clearSelection()
				let arr = _this.ruleForm.moban_arr[index].selectCoList
				if (arr && arr.length > 0) {
					arr.forEach((item, index) => {
						_this.$refs.coMuliTable.toggleRowSelection(item, true);
					});
				}
			})
		},
		muliSelectTableCo(e) {
			// console.log(e)
			let index = this.indexCo
			this.ruleForm.moban_arr[index].selectCoList = e
		},
		muliSelectTableCo_all(e) {
			// console.log(e)
			let index = this.indexCo
			this.ruleForm.moban_arr[index].selectCoList = e
		},
		closeCoList() {
			let _this = this
			let index = this.indexCo
			let checkedCount = this.ruleForm.moban_arr[index].selectCoList.length;
			this.ruleForm.moban_arr[index].checkAll = checkedCount === this.coList.length;
			this.ruleForm.moban_arr[index].isIndeterminate = checkedCount > 0 && checkedCount < this.coList.length;

			_this.showDialog_co = false
		},
		onSubmit(formName) {
			let _this = this
			let can = false
			let params = JSON.parse(JSON.stringify(_this.ruleForm));

			if (!params.plan_name) {
				this.$message.warning(`请填写问卷名称！`)
				return
			}
			if (!params.sta_end_time) {
				this.$message.warning(`选择调查时段！`)
				return
			}
			if (!params.moban_arr || params.moban_arr.length == 0) {
				this.$message.warning('请添加一条模板！')
				return
			} else {
				let relateArr = []
				params.moban_arr.map((a, ia) => {
					if (!a.ivst_id) {
						let name = ''
						switch (a.type) {
							case 0:
								name = '学生'
								break;
							case 1:
								name = '家长'
								break;
							case 2:
								name = '企业'
								break;
						}
						this.$message.warning(`问卷模板${ia + 1}请选择${name}模板！`)
						can = false
						return
					}
					if (!a.send_type || a.send_type.length == 0) {
						this.$message.warning(`问卷模板${ia + 1}请选择调查方式！`)
						can = false
						return
					}
					if ((a.type == 0 || a.type == 1) && (!a.target_user || a.target_user.length == 0)) {
						this.$message.warning(`问卷模板${ia + 1}请选择发送对象！`)
						can = false
						return
					}
					if (a.type == 2 && (!a.selectCoList || a.selectCoList.length == 0)) {
						this.$message.warning(`问卷模板${ia + 1}请选择发送对象！`)
						can = false
						return
					}
					can = true
					let obj = {}
					obj.type = a.type
					obj.ivst_id = a.ivst_id
					obj.send_type = a.send_type.join(',')
					if (a.type == 0 || a.type == 1) {
						obj.undone_count = a.target_user.length

						let users = a.target_user
						users.unshift('0')
						users.push('0')
						obj.target_user = users.join(',')
					}
					if (a.type == 2) {
						obj.undone_count = a.selectCoList.length

						let users = a.selectCoList.map(item => {
							return item.id
						})
						users.unshift(0)
						users.push(0)
						obj.target_user = users.join(',')
					}
					relateArr.push(obj)
				})

				params.moban_arr = relateArr
			}
			if (params.formal == null || params.formal == undefined) {
				this.$message.warning(`选择发布状态！`)
				return
			}
			// console.log(params)
			if (can) {
				this.$http.post("api/by_ivst_plan_edit", params).then((res) => {
					if (res.data.code == 200) {
						this.$message.success('提交成功！')
						this.showDialog = false;
						this.getlist();
					} else {
						this.$message.error(res.data.msg)
					}
				});
			}

		},
		exportReport(filename) {
			this.$message.warning("正在生成文件，请耐心等待！")
			let url = window.location.href.split("plan")[0]
			url = url + "report?auth=" + sessionStorage.getItem("auth") + "&plan_id=" + this.plan_id
			this.$http.post("api/print_report", {
				url: url,
				type: "pdf",
				showloading: true
			}).then((res) => {
				window.open(res.data.url, "_blank")
			});
		},
		downImage(e) {

			let that = this;

			function downloadFile(fileName, content) {
				let aLink = document.createElement('a');
				let blob = base64ToBlob(content); //new Blob([content]);

				let evt = document.createEvent("HTMLEvents");
				evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
				aLink.download = fileName;
				aLink.href = URL.createObjectURL(blob);

				// aLink.dispatchEvent(evt);
				aLink.click()
			}
			//base64转blob
			function base64ToBlob(code) {
				let parts = code.split(';base64,');
				let contentType = parts[0].split(':')[1];
				let raw = window.atob(parts[1]);
				let rawLength = raw.length;

				let uInt8Array = new Uint8Array(rawLength);

				for (let i = 0; i < rawLength; ++i) {
					uInt8Array[i] = raw.charCodeAt(i);
				}
				return new Blob([uInt8Array], {
					type: contentType
				});
			}

			function download() {
				let title = "";
				for (let item of that.dataList) {
					if (item.id == e) {
						title = item.plan_name
					}
				}
				let imgData = $("#qrcode_" + e).find("img").attr("src");
				downloadFile(title + ".png", imgData);
			}
			download()


		}
	},
};
</script>
<style type="text/less" lang="less">
.requiredLabel {

	.el-form-item__label {
		position: relative;
	}

	.el-form-item__label::before {
		content: '*';
		color: #ff5500;
		position: absolute;
		left: -2px;
	}
}

.cus_dialog_complete {
	width: 90%;
	height: 80vh;
	margin-top: 5vh !important;

	div {
		box-sizing: border-box
	}

	.el-dialog__body {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		height: calc(100% - 44px);

		.reportBox {
			height: 100%;

			.el-tabs {
				height: 100%;

				.el-tabs__content {
					height: calc(100% - 55px);
				}
			}

			.thumbnail {
				width: 100%;
				height: 100%;

				img {
					display: inline-block;
					max-width: 100%;
					max-height: 100%;
				}
			}
		}

		.el-tree-node__content {
			width: 100%;
			//background-color: pink;
		}
	}
}

.cus_dialog_preview {
	//width: 375px;
	//margin-top: 10vh;
	width: 1200px;
	height: 78vh;

	.el-dialog__body {
		height: calc(100% - 120px);

		/*滚动条背景*/
		::-webkit-scrollbar-track {
			background-color: #f8f8f8;
			border-radius: 6px;
		}

		.previewBox {
			height: 100%;

			.left {
				height: 100%;
				overflow-y: scroll;

				.tmItem {
					background-color: #f8f8f8;
					margin-bottom: 15px;
					padding: 10px;
					box-sizing: border-box;
					border-left: 5px solid #409eff;

					.tmTitle {
						font-size: 16px;
					}

					.tmOptions {
						font-size: 14px;
						flex-wrap: wrap;
					}
				}
			}

			.right {
				height: 100%;
				overflow-y: scroll;

			}
		}
	}
}


.cus_dialog_report {
	width: 90%;
	height: 90vh;
	margin-top: 5vh !important;

	.el-dialog__body {
		height: calc(100% - 120px);

		.reportBox {
			height: 100%;

			.el-tabs {
				height: 100%;

				.el-tabs__content {
					height: calc(100% - 55px);
				}
			}

			.thumbnail {
				width: 100%;
				height: 100%;

				img {
					display: inline-block;
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}

	.boxitem {
		width: 220px;
		height: 90px;
		display: inline-block;
		background-image: url(../../../../public/img/bx_bg.png);
		background-size: 100% 100%;
		margin-bottom: 20px;
		color: #4dadea;
		font-size: 16px;

		.boxdesc {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
		}
	}

}

.words {
	padding: 5px 10px;
	margin: 0 20px;
	text-align: center;
	border-bottom: 1px solid #ccc;
}

.qrcode img {
	width: 100%;
	height: 100%;
}
</style>
